import React from "react";

const Billing = () => {
  const invoices = [
    { date: "November, 2024", status: "Paid", amount: "$25.00" },
    { date: "October, 2024", status: "Paid", amount: "$25.00" },
    { date: "September, 2024", status: "Paid", amount: "$25.00" },
  ];

  const containerStyle = {
    maxWidth: "900px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  };

  const cardStyle = {
    border: "1px solid #ddd",
    borderRadius: "8px",
    marginBottom: "20px",
    padding: "16px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
  };

  const titleStyle = {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "12px",
  };

  const flexStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const addButtonStyle = {
    color: "#4F46E5",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    textDecoration: "none",
  };

  const editButtonStyle = {
    color: "#4F46E5",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    textDecoration: "none",
  };

  const additionalTextStyle = {
    color: "#666",
    marginTop: "8px",
    fontSize: "14px",
    lineHeight: "1.5",
  };

  const cardBillStyle = {
    border: "1px solid #ddd",
    borderRadius: "8px",
    marginBottom: "20px",
    padding: "16px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
  };

  const titleBillStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const descriptionStyle = {
    color: "#666",
    fontSize: "14px",
    lineHeight: "1.5",
  };

  const balanceStyle = {
    fontSize: "32px",
    fontWeight: "bold",
    marginTop: "10px",
  };

  const inputContainerStyle = {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  };

  const inputStyle = {
    padding: "8px 12px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    marginRight: "8px",
    outline: "none",
  };

  const buttonStyle = {
    padding: "8px 16px",
    backgroundColor: "#f0f0f0",
    border: "none",
    borderRadius: "4px",
    color: "#aaa",
    cursor: "not-allowed",
    fontWeight: "bold",
  };
  return (
    <>
      <section class="">
        <br/>
      <div class="upper-title-box">
            <h3>Billing Information</h3>
            <div class="text">Ready to jump back in?</div>
          </div>

      <div className="plan-card-container">
        <div className="plan-card">
          {/* Top Title */}
          <h2 className="plan-title">Your Plan</h2>

          <div className="plan-content">
            {/* Left Section */}
            <div className="current-plan-label">Current Plan</div>

            {/* Right Section */}
            <div className="plan-details">
              <h3 className="plan-name">Hobby</h3>
              <p className="plan-description">
                Perfect for hobbyists and students.{" "}
                <a href="#" className="plan-link">
                  Plan Benefits
                </a>
              </p>

              {/* Bottom Section */}
              <div className="plan-actions">
                <span className="arrow-icon">&#8594;</span>
                <button className="update-plan-button">✏️ Update Plan</button>
              </div>
            </div>
          </div>

          {/* Decorative Elements */}
          <div className="circle-decoration top-right"></div>
          <div className="circle-decoration bottom-left"></div>
        </div>
      </div>
      {/* <div className="plan-card-container">
        <div className="plan-card">
         
          <h2 className="plan-title">Payment Method</h2>

          <div className="plan-content">
            
            <div className="plan-details">
              <h3 className="plan-name">Cards</h3>
              <p className="plan-description">
                No{" "}
                <a href="#" className="plan-link">
                  Cards
                </a>{" "}
                on file{" "}
              </p>

             
              <div className="plan-actions">
                <span className="arrow-icon">&#8594;</span>
                <button className="update-plan-button">+ Add Card</button>
              </div>
            </div>
          </div>

        
          <div className="circle-decoration top-right"></div>
          <div className="circle-decoration bottom-left"></div>
        </div>
      </div> */}
      <div style={containerStyle}>
        {/* Payment Method Section */}
        <div style={cardStyle}>
          <h2 style={titleStyle}>Payment Method</h2>
          <div style={flexStyle}>
            <span>No card on file.</span>
            <a href="#" style={addButtonStyle}>
              <span style={{ fontSize: "18px", marginRight: "6px" }}>+</span>
              Add Card
            </a>
          </div>
        </div>

        {/* Billing Information Section */}
        <div style={cardStyle}>
          <h2 style={titleStyle}>Billing Information</h2>
          <div style={flexStyle}>
            <div>
              <p style={{ fontWeight: "bold" }}>Additional Information</p>
              <p>No info provided.</p>
              <p style={additionalTextStyle}>
                Provide additional billing information such as company name,
                address, or VAT number.
              </p>
            </div>
            <a href="#" style={editButtonStyle}>
              <span style={{ marginRight: "6px" }}>✏️</span>Edit
            </a>
          </div>
        </div>
        <div style={cardBillStyle}>
          <h2 style={titleBillStyle}>Unbilled Charges</h2>
          <p style={descriptionStyle}>
            Amounts displayed have been accrued within the month to date.
          </p>
          <p>No pending charges.</p>
        </div>

        {/* Credit Balance Section */}
        <div style={cardBillStyle}>
          <h2 style={titleBillStyle}>Credit Balance</h2>
          <p style={descriptionStyle}>
            The balance will be applied to the amount due on your next invoice.
          </p>
          <div>
            <p style={{ fontSize: "12px", fontWeight: "bold", color: "#666" }}>
              TOTAL BALANCE
            </p>
            <p style={balanceStyle}>$0.00</p>
          </div>
          <div style={inputContainerStyle}>
            <input
              type="text"
              placeholder="Enter promo code"
              style={inputStyle}
              disabled
            />
            <button style={buttonStyle} disabled>
              Apply
            </button>
          </div>
        </div>

        <div className="invoice-container">
          {/* Header */}
          <div className="header-section">
            <h2 className="invoice-title">Invoice History</h2>
            <p className="invoice-subtitle">
              View or download your past invoices.
            </p>
          </div>

          {/* Table */}
          <div className="table-container">
            <table className="invoice-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoices.map((invoice, index) => (
                  <tr key={index} className="row-animate">
                    <td>
                      <a href="#" className="date-link">
                        {invoice.date}
                      </a>
                    </td>
                    <td>
                      <span className="status-badge">✔ Paid</span>
                    </td>
                    <td className="amount">{invoice.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Footer */}
          <div className="footer-section">
            <button className="view-more-btn">View More</button>
          </div>
        </div>
      </div>
      </section>
    </>
  );
};

export default Billing;
